import styled from "styled-components";

export const GridWrapp = styled.div`
  display: grid;
  grid-template-columns: 1fr 40px 40px;
  grid-gap: 10px;
  padding: 24px;
  align-items: center;
  border-bottom: 1px solid rgb(231, 227, 235);
`;

export const TradeWrapper = styled.div`
  border-radius: 24px;
  max-width: 436px;
  width: 100%;
  z-index: 1;
  background: rgb(255, 255, 255);
  border-radius: 24px;
  color: rgb(40, 13, 95);
  overflow: hidden;
  position: relative;
  padding: 1px 1px 3px;
  margin: 0 auto;
  border: 1px solid rgb(231, 227, 235);
`;
