import styled from "styled-components";

export const Container = styled.div`
  max-width: 1050px;
  width: 100%;
  padding: 0 100px;
  margin: 100px auto;

  @media (max-width: 1200px) {
    max-width: 450px;
    padding: 0;
  }
`;

export const Wrapper = styled.div`
  max-width: 960px; /* 20px smaller, to fit the paddings on the sides */
  padding-right: 10px;
  padding-left: 10px;
`;

export const TitleH2 = styled.h2`
  font-weight: 600;
  line-height: 1.1;
  color: rgb(40, 13, 95);
`;

export const TextP = styled.p`
  font-weight: 500;
  color: rgb(40, 13, 95);
`;

export const InnerContentWrapper = styled.div`
  align-items: center;
  justify-content: space-between;
  padding: 1px;
  width: 100%;
  box-sizing: border-box;
`;

export const LabelField = styled.div`
  background-color: #eeeeee;
  border-bottom: 1px solid rgb(231, 227, 235);
`;

export const Input = styled.input`
  border: none;
  background-color: transparent;
  display: block;
  margin: 0 0 1em;
  padding: 10px 0;
  font-weight: 600;

  ::placeholder {
    color: #7a6eaa;
  }
  :focus {
    border: none;
  }
`;
