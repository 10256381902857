import React from "react";
import Liquidity from "./Liquidity/Liquidity.comp";
import Swap from "./Swap/Swap.comp";
import { Container } from "../../styles/common.styles";
import { TradeWrapper } from "./Trade.styles";

const Trade = ({ isSwap }) => {
  return (
    <Container>
      <TradeWrapper>
        <>{isSwap ? <Swap /> : <Liquidity />}</>
      </TradeWrapper>
    </Container>
  );
};

export default Trade;
