import Sidebar from "./components/Sidebar/Sidebar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import Trade from "./pages/Trade";
import Profile from "./pages/Profile";
import styled from "styled-components";
import "@fontsource/roboto-condensed";

function App() {
  const MainContainer = styled.div`
    box-sizing: border-box;
  `;
  return (
    <Router>
      <MainContainer>
        <Sidebar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route
            path="/trade-swap"
            exact
            render={(props) => <Trade isSwap {...props} />}
          />
          <Route path="/trade-liquidity" exact component={Trade} />
          <Route path="/profile" exact component={Profile} />
        </Switch>
      </MainContainer>
    </Router>
  );
}

export default App;
