import styled from "styled-components";
import { Input } from "../../../styles/common.styles";

export const GridWrapp2 = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 10px;
  padding: 15px;
`;

export const SwapLabelField = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  padding: 10px;
  background-color: rgb(231, 227, 235);
  align-items: center;
`;

export const DropDownContainer = styled.div`
  height: fit-content;
  display: grid;
  cursor: pointer;
  align-items: center;
  grid-template-columns: 2fr 3fr 10px;
  grid-gap: 10px;
`;

export const LabelText = styled(Input)`
  color: #7a6eaa;
  margin-top: 10px;
`;
export const TextBold = styled.p`
  color: #7a6eaa;
  margin-top: 10px;
  font-weight: 600;
`;
