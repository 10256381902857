import { IoMdSettings } from "react-icons/io";
import { RiArrowDownSFill, RiHistoryFill } from "react-icons/ri";
import { SiBitcoin } from "react-icons/si";
import {
  InnerContentWrapper,
  TextP,
  TitleH2,
} from "../../../styles/common.styles";
import { GridWrapp } from "../Trade.styles";
import {
  DropDownContainer,
  GridWrapp2,
  LabelText,
  TextBold,
  SwapLabelField,
} from "./Swap.styles";

const Swap = () => {
  return (
    <InnerContentWrapper>
      <GridWrapp>
        <div>
          <TitleH2>Swap</TitleH2>
          <TextBold>Trade tokens in an instant</TextBold>
        </div>
        <IoMdSettings
          size={24}
          style={{ justifySelf: "flex-end", cursor: "pointer" }}
        />
        <RiHistoryFill
          size={24}
          style={{ justifySelf: "flex-end", cursor: "pointer" }}
        />
      </GridWrapp>
      <GridWrapp2>
        <SwapLabelField>
          <div>
            <TextP>From</TextP>
            <LabelText type="number" placeholder="0.0" step="any" />
          </div>
          <DropDownContainer>
            <SiBitcoin size={20} />
            <TextP>BTC</TextP>
            <RiArrowDownSFill />
          </DropDownContainer>
        </SwapLabelField>

        <SwapLabelField>
          <div>
            <TextP>To</TextP>
            <LabelText type="number" placeholder="0.0" step="any" />
          </div>
          <DropDownContainer>
            <SiBitcoin size={20} />
            <TextP>BTC</TextP>
            <RiArrowDownSFill />
          </DropDownContainer>
        </SwapLabelField>
      </GridWrapp2>
    </InnerContentWrapper>
  );
};

export default Swap;
