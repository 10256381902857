import React from "react";
import { Container } from "../../styles/common.styles";

const Home = () => {
  return (
    <Container className="home">
      <h1>Kugabana</h1>
    </Container>
  );
};

export default Home;
