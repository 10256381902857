import { FaPlus } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import { RiHistoryFill } from "react-icons/ri";
import {
  InnerContentWrapper,
  LabelField,
  TextP,
  TitleH2,
  Wrapper,
} from "../../../styles/common.styles";
import { TextBold } from "../Swap/Swap.styles";
import { GridWrapp } from "../Trade.styles";
import { TextWhite, SpacerDiv, ButtonLiquidity } from "./Liquidity.styles";

const Liquidity = () => {
  return (
    <InnerContentWrapper>
      <GridWrapp>
        <div>
          <TitleH2>Your Liquidity</TitleH2>
          <TextBold>Remove liquidity to receive tokens back</TextBold>
        </div>
        <IoMdSettings
          size={24}
          style={{ justifySelf: "flex-end", cursor: "pointer" }}
        />
        <RiHistoryFill
          size={24}
          style={{ justifySelf: "flex-end", cursor: "pointer" }}
        />
      </GridWrapp>
      <LabelField>
        <SpacerDiv>
          <TextP>Connect to a wallet to view your liquidity</TextP>
        </SpacerDiv>
      </LabelField>
      <Wrapper>
        <ButtonLiquidity>
          <FaPlus />
          <TextWhite>Add Liquidity</TextWhite>
        </ButtonLiquidity>
      </Wrapper>
    </InnerContentWrapper>
  );
};

export default Liquidity;
