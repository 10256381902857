import styled from "styled-components";
import Button from "../../../components/Buttons/Button.styles";
import { TitleH2 } from "../../../styles/common.styles";

export const SpacerDiv = styled.div`
  text-align: center;
  padding: 20px 0;
`;

export const TextWhite = styled(TitleH2)`
  color: white;
  font-size: 16px;
  margin-left: 10px;
`;

export const ButtonLiquidity = styled(Button)`
  background-color: #1fc7d4;
  border-radius: 10px;
  border: none;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 0;
  text-align: center;
  justify-content: center;
  padding: 20px 0;
`;
